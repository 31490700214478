import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ArrowDownIcon from '../icons/ArrowDown';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import VirtualKeyboard from './VirtualKeyboard';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

export interface SelectOption {
  id: string;
  description: string;
}

interface SelectNewProps {
  options?: SelectOption[];
  onChange?: (option: SelectOption) => void;
  setSearchQuery?: (text: string) => void;
  searchQuery?: string;
  zIndex?: number;
  disabled?: boolean;
  setIsIntersect?: any;
  virtualKeyboard?: boolean;
}

const SelectNew: React.FC<SelectNewProps> = ({
  options = [],
  onChange,
  setSearchQuery,
  searchQuery,
  zIndex,
  disabled = false,
  setIsIntersect,
  virtualKeyboard = false,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >();
  const [showKeyboard, setShowKeyboard] = useState(false);

  const optionsRef = useRef<HTMLDivElement>(null);
  const keyboardRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onScreen = useIntersectionObserver(optionsRef, {});

  useEffect(() => {
    if (onScreen) {
      setIsIntersect && setIsIntersect(true);
    } else {
      setIsIntersect && setIsIntersect(false);
    }
  }, [onScreen]);

  const handleOptionClick = (option: SelectOption) => {
    if (!disabled) {
      setSelectedOption(option);
      if (onChange) {
        onChange(option);
      }
      setShowOptions(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      const query = e.target.value;
      setSearchQuery && setSearchQuery(query);
    }
  };

  const handleFocus = () => {
    if (!disabled) {
      setShowKeyboard(true);
    }
  };

  const handleKeyPress = (key: string) => {
    if (!disabled) {
      if (key === 'delete') {
        setSearchQuery &&
          searchQuery &&
          setSearchQuery(searchQuery.slice(0, -1));
      } else {
        setSearchQuery && setSearchQuery(searchQuery ? searchQuery + key : key);
      }
    }
  };

  const handleCloseKeyboard = () => {
    setShowKeyboard(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key >= '0' && e.key <= '9') || e.key === 'Backspace') {
      let newValue;
      if (e.key === 'Backspace') {
        newValue = searchQuery?.slice(0, -1);
      } else {
        newValue = searchQuery + e.key;
      }
      setSearchQuery && setSearchQuery(newValue as any);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (showKeyboard && optionsRef.current) {
      const rect = optionsRef.current.getBoundingClientRect();
      const keyboardRoot = document.getElementById('keyboard-root');
      if (keyboardRoot) {
        keyboardRoot.style.position = 'absolute';
        keyboardRoot.style.left = `${rect.left}px`;
        keyboardRoot.style.top = `${rect.bottom}px`;
      }
    }
  }, [showKeyboard]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        keyboardRef.current &&
        !keyboardRef.current.contains(event.target)
      ) {
        setShowKeyboard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      style={{ zIndex }}
      className={`flex justify-center items-center h-[64px] border rounded-[8px] relative`}
    >
      <div
        className={`w-full min-h-[68px] cursor-pointer flex justify-between items-center p-[20px_8px_20px_16px] text-[#00000099] ${disabled ? 'text-[#0000003D]' : ''}`}
        onClick={() => !disabled && setShowOptions(!showOptions)}
      >
        {selectedOption ? selectedOption.description : 'Не выбрано'}
        <span className="material-icons">
          {showOptions ? (
            <ArrowUpIcon disabled={disabled} />
          ) : (
            <ArrowDownIcon disabled={disabled} />
          )}
        </span>
      </div>

      {showOptions && (
        <div
          ref={optionsRef}
          className="flex flex-col absolute top-[64px] w-full rounded-[8px] bg-white border mt-[8px] max-h-[240px] overflow-auto"
        >
          {virtualKeyboard && (
            <input
              ref={inputRef}
              inputMode="numeric"
              placeholder="Поиск"
              className="border rounded-[8px] input-no-outline h-[48px] m-[10px] p-[16px]"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              onTouchStart={handleFocus}
              onKeyDown={handleKeyDown}
              readOnly={true}
            />
          )}

          {showKeyboard &&
            ReactDOM.createPortal(
              <div ref={keyboardRef}>
                <VirtualKeyboard
                  onKeyPress={handleKeyPress}
                  onClose={handleCloseKeyboard}
                />
                ,
              </div>,
              document.getElementById('keyboard-root')!
            )}

          {options?.length > 0 ? (
            options.map((option) => (
              <div
                key={option.id}
                className={`p-[16px] hover:bg-gray-100 cursor-pointer ${selectedOption?.id === option.id ? 'bg-gray-200' : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                {option.description}
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center mb-4">
              {searchQuery ? 'Рулон не найден' : 'Нет данных'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectNew;
