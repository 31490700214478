import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import VirtualKeyboard from './VirtualKeyboard';

interface CounterProps {
  upCount: () => void;
  downCount: () => void;
  value: number;
  setValue: (value: number) => void;
}

const Counter: React.FC<CounterProps> = ({
  upCount,
  downCount,
  value,
  setValue,
}) => {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef<HTMLDivElement>(null);

  const handleDownCount = () => {
    if (value > 0) {
      downCount();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      setValue(newValue);
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === 'delete') {
      const newValue =
        value.toString().slice(0, -1) !== ''
          ? parseInt(value.toString().slice(0, -1), 10)
          : 0;
      setValue(newValue);
    } else {
      const newValue = parseInt(value.toString() + key, 10);
      if (!isNaN(newValue) && newValue >= 0) {
        setValue(newValue);
      }
    }
  };

  const handleCloseKeyboard = () => {
    setShowKeyboard(false);
  };

  const handleFocus = (e: any) => {
    e.preventDefault();

    setShowKeyboard(true);
  };

  useEffect(() => {
    if (showKeyboard && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const keyboardRoot = document.getElementById('keyboard-root');
      if (keyboardRoot) {
        const keyboardWidth = 300;
        const screenWidth = window.innerWidth;
        const leftPosition = (screenWidth - keyboardWidth) / 3;

        keyboardRoot.style.position = 'absolute';
        keyboardRoot.style.left = `${leftPosition}px`;
        keyboardRoot.style.top = `${rect.bottom}px`;
      }
    }
  }, [showKeyboard]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (showKeyboard) {
        const key = e.key;
        if (key === 'Backspace') {
          const newValue =
            value.toString().slice(0, -1) !== ''
              ? parseInt(value.toString().slice(0, -1), 10)
              : 0;
          setValue(newValue);
        } else if (!isNaN(Number(key)) && key !== ' ') {
          const newValue = parseInt(value.toString() + key, 10);
          if (!isNaN(newValue) && newValue >= 0) {
            setValue(newValue);
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showKeyboard, value, setValue]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        keyboardRef.current &&
        !keyboardRef.current.contains(event.target)
      ) {
        setShowKeyboard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="w-fit justify-center items-center flex text-[14px] gap-[5px] border rounded-[4px]">
      <div
        onClick={handleDownCount}
        className={`select-none px-[20px] py-[20px] text-[18px] cursor-pointer hover:bg-[#4E67EA] active:bg-[#3449ad] hover:text-white ${value > 0 ? 'text-gray-500' : 'text-[#0000001F]'}`}
      >
        -
      </div>
      <input
        ref={inputRef}
        type="text"
        className="w-[50px] text-center select-none px-[1px] py-[20px] text-[18px]"
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onTouchStart={handleFocus}
        readOnly={true}
      />
      {showKeyboard &&
        ReactDOM.createPortal(
          <div ref={keyboardRef}>
            <VirtualKeyboard
              onKeyPress={handleKeyPress}
              onClose={handleCloseKeyboard}
            />
            ,
          </div>,
          document.getElementById('keyboard-root')!
        )}
      <div
        onClick={() => upCount()}
        className="select-none px-[20px] py-[20px] text-[18px] text-gray-500 cursor-pointer hover:bg-[#4E67EA] active:bg-[#3449ad] hover:text-white"
      >
        +
      </div>
    </div>
  );
};

export default Counter;
