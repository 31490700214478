import React from 'react';

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0 z-50 flex justify-center">
        <div
          className="bg-white overflow-auto relative"
          style={{
            width: '580px',
            height: '620px',
            padding: '32px',
            borderRadius: '8px',
          }}
        >
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-3 text-[#00000099] text-2xl leading-none"
            style={{ cursor: 'pointer' }}
          >
            &times; {/* Крестик для закрытия */}
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
